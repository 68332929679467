import { Typography, Button } from '@components/ui';

interface LoggedInProps {
  onLogout: () => void;
}

export function LoggedIn(props: LoggedInProps) {
  const { onLogout } = props;

  return (
    <>
      <Typography sx={{ margin: '25px 0' }} variant="body2">
        You have already logged in. Do you need to log out?
      </Typography>

      <Button onClick={onLogout} size="large" color="secondary" variant="contained" fullWidth>
        Log out
      </Button>
    </>
  );
}
