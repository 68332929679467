import { createContext, useContext } from 'react';
import { MessagingService } from 'src/services/messaging';

const ctx = createContext<MessagingService>(new MessagingService());

export const MessagingProvider = ctx.Provider;

export function useMessaging() {
  return useContext(ctx);
}
