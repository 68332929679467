export enum ERRORS {
  UNKNOWN = 'Unknown error',
  USERNAME_EMPTY = 'Username should not be empty',
  USERNAME_RULE = 'Your username can be a maximum of 20 characters',
  USERNAME_RULE_CONTAINS = 'Username should contain only letters, numbers and underscore',
  USERNAME_BANNED_WORD = 'Please enter a different username',
  EMAIL_INCORRECT = 'Incorrect email',
  EMAIL_REGISTERED = 'This email has already been registered. Please sign in',
  EMAIL_NOT_FOUND = 'This email cannot be found',
  EMAIL_EMPTY = 'Email address should not be empty',
  EMAIL_ALREADY_VERIFIED = 'Your email is already verified!',
  PASSWORD_EMPTY = 'Password should not be empty',
  PASSWORD_MATCH = 'Your passwords don\'t match',
  PASSWORD_RULE_LENGTH = 'Password must be greater than 6 characters and less than 30.',
  PASSWORD_RULE_CONTAINS = 'Password should contain at least one lowercase letter, one uppercase letter, and a number',
  PASSWORD_INCORRECT = 'Incorrect password',
}

export const EMAIL_RE = /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PASSWORD_RE = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
export const USERNAME_RE = /^[A-Za-z0-9_]+$/gi;
