import styled from '@emotion/styled';
import { ErrorOutline } from '@components/icons';
import { css } from '@emotion/react';

export const ErrorWrap = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 14px 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #d32f2f;
  border-radius: 4px;
  margin-bottom: 17px;
`;

export const ErrorContent = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #541313;
  padding-top: 3px;
`;

const iconStyle = css`
  font-size: 19px;
  color: #d32f2f;
  margin-right: 14px;
`;

export const Icon = styled(ErrorOutline)(iconStyle);
