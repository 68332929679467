import { Box } from '@components/Box';
import { Typography, Button } from '@components/ui';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'src/routes';

export function AlreadyHaveBlock() {
  return (
    <Box>
      <Typography sx={{ opacity: 0.7, marginBottom: '7px' }}>Already have a Faraway account?</Typography>
      <Button component={Link} to={RoutePaths.SIGN_IN} variant="text" color="secondary">
        Sign In
      </Button>
    </Box>
  );
}
