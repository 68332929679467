import { Button, CircularProgress } from '@components/ui';
import { FailureBlock } from '@components/FailureBlock';
import { SuccessBlock } from '@components/SuccessBlock';
import { useAPI, useMessaging } from '@hooks';
import { useEffect, useState } from 'react';
import { FetchingContainer } from './Verify.styled';
import { Link, useSearchParams } from 'react-router-dom';
import { RoutePaths } from 'src/routes';
import { ERRORS } from 'src/constants';

export function Verify() {
  const [searchParams] = useSearchParams();
  const { accountsClient } = useAPI();
  const messaging = useMessaging();
  const [fetching, setFetching] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('Your email is confirmed!');

  useEffect(() => {
    const token = searchParams.get('token');

    if (!token) {
      setFetching(false);
      return;
    }

    accountsClient
      .verifyAccount(token)
      .then(() => {
        setVerified(true);
        setFetching(false);
        messaging.verifySuccess();
      })
      .catch((e) => {
        setFetching(false);

        if (e.message === ERRORS.EMAIL_ALREADY_VERIFIED) {
          setVerified(true);
          setMessage(e.message);
        } else {
          setVerified(false);
        }

        messaging.verifyError(e.message);
      });
  }, []);

  if (fetching) {
    return (
      <FetchingContainer>
        <CircularProgress />
      </FetchingContainer>
    );
  }

  if (!verified && !fetching) {
    return <FailureBlock size="large" message="Something went wrong" />;
  }

  return (
    <>
      <SuccessBlock size="large" message={message} />
      <Button component={Link} to={RoutePaths.SIGN_IN} size="large" color="secondary" variant="contained" fullWidth>
        Go to my account
      </Button>
    </>
  );
}
