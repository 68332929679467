import styled from '@emotion/styled';
import { Typography } from '@components/ui';

export interface Props {
  size?: 'default' | 'large';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ea3a59;
  margin: 26px 0;
`;

export const Message = styled(Typography)<Props>((props) => ({
  margin: '12px 0',
  textAlign: 'center',
  fontSize: props.size === 'large' ? '24px' : '16px',
  fontWeight: props.size === 'large' ? '700' : '550',
}));
