import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
`;

export const PreloadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
