import { REDIRECT_DOMAINS_WHITE_LIST } from 'src/settings';

class UrlService {
  private whitelist = REDIRECT_DOMAINS_WHITE_LIST.map((domain) => {
    domain = domain.replaceAll('.', '\\.');
    if (domain.startsWith('*')) {
      return new RegExp(domain.replace('*', '^.*') + '$');
    }

    return new RegExp(`^${domain}$`);
  });

  checkIsValidHostname = (hostname?: string): boolean => {
    if (!hostname) {
      return false;
    }
    return this.whitelist.some((re) => re.test(hostname));
  };

  checkIsValidURL = (url: string): boolean => {
    try {
      const { hostname } = new window.URL(url);
      return this.checkIsValidHostname(hostname);
    } catch {
      return false;
    }
  };
}

export const urlService = new UrlService();
