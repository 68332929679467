import { MessagingTransport } from './typings';

export class UrlTransport extends MessagingTransport {
  constructor(private urlString: string) {
    super();
  }

  authSuccess() {
    return window.location.replace(this.urlString);
  }
}
