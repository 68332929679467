import { useState } from 'react';

export function useBooleanToggle(defaultValue: boolean) {
  return useToggle([true, false], defaultValue);
}

export function useToggle<T extends any[]>(
  availableValues: T,
  defaultValue: typeof availableValues[number]
): [typeof availableValues[number], () => void] {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = () => {
    const newValueIndex = availableValues.indexOf(value) ? 0 : 1;
    setValue(availableValues[newValueIndex]);
  };

  return [value, toggleValue];
}
