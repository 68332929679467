import { useState } from 'react';
import { ResetForm } from './ResetForm';
import { NeedConfirm } from './NeedConfirm';

export function Reset() {
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const handleConfirmed = () => setConfirmed(true);

  return <>{!confirmed ? <ResetForm onSuccess={handleConfirmed} /> : <NeedConfirm />}</>;
}
