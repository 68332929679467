import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DeviceOrientation, DeviceParams, DeviceType } from './device.typings';
import { SCREEN_MOBILE_BREAKPOINT, SCREEN_TABLET_BREAKPOINT } from 'src/settings';

const ctx = createContext<DeviceParams>({
  device: 'desktop',
  orientation: 'landscape',
  isDesktop: true,
  isLandscape: true,
  isPortrait: false,
  isMobile: false,
  isTablet: false,
});
export const DeviceProvider = ctx.Provider;

export function useDevice() {
  return useContext(ctx);
}

const MEDIA_QUERIES: Record<DeviceType, string> = {
  mobile: `(max-width: ${SCREEN_MOBILE_BREAKPOINT}px)`,
  tablet: `(min-width: ${SCREEN_MOBILE_BREAKPOINT + 1}px) and (max-width: ${SCREEN_TABLET_BREAKPOINT}px)`,
  desktop: `(min-width: ${SCREEN_TABLET_BREAKPOINT + 1}px)`,
};

function findDeviceByQuery(query: string) {
  return Object.keys(MEDIA_QUERIES).find((key) => MEDIA_QUERIES[key as DeviceType] === query) as DeviceType;
}

export function useDeviceDetector(): DeviceParams {
  const mediaQueries = useMemo(() => Object.values(MEDIA_QUERIES).map((query) => window.matchMedia(query)), []);

  const [orientation] = useState<DeviceOrientation>('landscape'); // TODO
  const [device, setDevice] = useState<DeviceType>(
    findDeviceByQuery(mediaQueries.find((query) => query.matches)?.media || 'desktop')
  );

  const matchMediaHandler = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setDevice(findDeviceByQuery(e.media));
    }
  }, []);

  useEffect(() => {
    mediaQueries.forEach((query) => query.addEventListener('change', matchMediaHandler));

    return () => {
      mediaQueries.forEach((query) => query.removeEventListener('change', matchMediaHandler));
    };
  }, []);

  return {
    device,
    orientation,
    isMobile: device === 'mobile',
    isTablet: device === 'tablet',
    isDesktop: device === 'desktop',
    isLandscape: orientation === 'landscape',
    isPortrait: orientation === 'portrait',
  };
}
