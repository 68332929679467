import { ApiService } from '../apiService';
import { ApiClient } from './apiClient';
import { AccountsPromiseClient, GetAccount, CreateAccount, UpdateAccount, VerifyAccount, JWT } from '@modules/api';
export class AccountsClient extends ApiClient<AccountsPromiseClient> {
  constructor(api: ApiService) {
    super(api, AccountsPromiseClient);
  }

  getAccount = () => {
    const req = new GetAccount.Request();
    return this.grpc
      .getAccount(req)
      .then((res) => this.normalizeResponse<GetAccount.Response.AsObject>(res))
      .catch(this.errorHandler);
  };

  createAccount = (email: string, username: string, password: string) => {
    const req = new CreateAccount.Request();
    req.setEmail(email);
    req.setUsername(username);
    req.setPassword(password);

    return this.grpc
      .createAccount(req)
      .then((res) => this.normalizeResponse<CreateAccount.Response.AsObject>(res))
      .catch(this.errorHandler);
  };

  verifyAccount = (token: string) => {
    const req = new VerifyAccount.Request();
    const jwt = new JWT();
    jwt.setValue(token);
    req.setToken(jwt);

    return this.grpc
      .verifyAccount(req)
      .then((res) => this.normalizeResponse<VerifyAccount.Response.AsObject>(res))
      .catch(this.errorHandler);
  };

  updatePassword = (token: string, password: string) => {
    const req = new UpdateAccount.Request();
    req.setNewPassword(password);

    return this.grpc
      .updateAccount(req, { 'x-idp-token': token })
      .then((res) => this.normalizeResponse<UpdateAccount.Response.AsObject>(res))
      .catch(this.errorHandler);
  };
}
