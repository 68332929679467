import { Container, Message, Props as StyledProps } from './FailureBlock.styled';
import { Error } from '@components/icons';

type FailureBlockProps = StyledProps & {
  message?: React.ReactNode;
};

export function FailureBlock(props: FailureBlockProps) {
  const { message, size = 'default' } = props;

  return (
    <Container>
      <Error sx={{ fontSize: '68px' }} />
      <Message size={size}>{message}</Message>
    </Container>
  );
}
