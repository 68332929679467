import { ERRORS } from 'src/constants';

export const SERVER_ERRORS_MAP: Record<string, string> = {
  'wrong credentials': ERRORS.PASSWORD_INCORRECT,
  'already exists': ERRORS.EMAIL_REGISTERED,
  'invalid CreateAccount_Request.Username: value length must be at most 20 runes': ERRORS.USERNAME_RULE,
  'nothing found': ERRORS.EMAIL_ALREADY_VERIFIED,
  'password should contain at least one lowercase letter, uppercase letter and a digit': ERRORS.PASSWORD_RULE_CONTAINS,
  'the word is banned by bad list': ERRORS.USERNAME_BANNED_WORD,
};

export const ERROR_FIELD: Partial<Record<keyof typeof ERRORS, string>> = {
  USERNAME_EMPTY: 'username',
  USERNAME_RULE: 'username',
  USERNAME_RULE_CONTAINS: 'username',
  EMAIL_EMPTY: 'email',
  EMAIL_INCORRECT: 'email',
  EMAIL_NOT_FOUND: 'email',
  EMAIL_REGISTERED: 'email',
  PASSWORD_EMPTY: 'password',
  PASSWORD_INCORRECT: 'password',
  PASSWORD_MATCH: 'password2',
  PASSWORD_RULE_CONTAINS: 'password',
  PASSWORD_RULE_LENGTH: 'password',
};
