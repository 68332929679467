import { useMemo, useState } from 'react';
import { ErrorOption, FieldError, FieldPath, FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

type UseFormExtendedProps<TFieldValues, TContext> = UseFormProps<TFieldValues, TContext>;

type UseFormExtendedReturn<TFieldValues, TContext> = UseFormReturn<TFieldValues, TContext> & {
  error: FieldError;
  clearCustomError: () => void;
};

type UseFormExtendedSetError<TFieldValues extends FieldValues> = (
  name: FieldPath<TFieldValues> | null,
  error: ErrorOption,
  options?: {
    shouldFocus: boolean;
  }
) => void;

export function useFormExtended<TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormExtendedProps<TFieldValues, TContext>
): UseFormExtendedReturn<TFieldValues, TContext> {
  const form = useForm(props);
  const [customError, setCustomError] = useState<ErrorOption>();

  const error = useMemo<FieldError>(() => {
    const errors = Object.values(form.formState.errors);
    if (customError) {
      return customError;
    }

    return errors.length ? errors[0] : '';
  }, [form.formState, customError]);

  const setError: UseFormExtendedSetError<TFieldValues> = (name, error, options) => {
    if (!name) {
      setCustomError(error);
      return;
    }

    form.setError(name, error, options);
  };

  const clearCustomError = () => {
    setCustomError(undefined);
  };

  const extendedForm = useMemo(() => {
    return Object.assign(form, { error, setError, clearCustomError });
  }, [form, error]);

  return extendedForm;
}
