import { ApiService } from '@services/apiService';
import { ERROR_FIELD, SERVER_ERRORS_MAP } from './errors';

interface Response<T> {
  toObject(): T;
}

type PromiseClientConstructor = ClassConstructor<
  any,
  [string, null | { [index: string]: string } | undefined, null | { [index: string]: any } | undefined]
>;

export abstract class ApiClient<T> {
  grpc: T;

  constructor(private apiService: ApiService, private _grpc: PromiseClientConstructor) {
    this.grpc = new _grpc(apiService.server, apiService.metadata, apiService.options) as T;
  }

  normalizeResponse<T>(response: Response<T>): T {
    return response.toObject();
  }

  errorHandler = (e: Error): PromiseLike<ErrorResponse> => {
    const res: ErrorResponse = {
      message: SERVER_ERRORS_MAP[e.message] || e.message,
      field: (ERROR_FIELD as any)[e.message] || null,
      base: e,
    };
    return Promise.reject(res);
  };

  protected grpcWithMetadata = (metadata: { [key: string]: any }) => {
    return new this._grpc(this.apiService.server, metadata, this.apiService.options) as T;
  };
}

// ----------------------------------
// Typings
//
export interface ErrorResponse {
  message: string;
  field: string | null;
  base: any;
}
