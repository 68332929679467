import { MessagingTransport } from './typings';

const POST_MESSAGE_ID = 'faraway_auth' as const;

export class PostMessageTransport extends MessagingTransport {
  private target = window.opener;

  private sendMessage = <K extends keyof PostMessageMap>(type: K, payload: PostMessageMap[K]) => {
    this.target.postMessage({ id: POST_MESSAGE_ID, type, payload }, '*');
  };

  sendInfo(info: any) {
    this.sendMessage('INFO', info);
  }

  init(): void {
    this.sendMessage('INIT', null);
  }

  alreadyAuthed(): void {
    this.sendMessage('ALREADY_AUTHED', null);
  }

  authSuccess() {
    this.sendMessage('AUTH_SUCCESS', null);
  }

  authError(error: string): void {
    this.sendMessage('AUTH_ERROR', { error });
  }

  registerSuccess(): void {
    this.sendMessage('REGISTER_SUCCESS', null);
  }

  registerError(error: string): void {
    this.sendMessage('REGISTER_ERROR', { error });
  }

  resetPasswordSuccess(): void {
    this.sendMessage('RESET_PASSWORD_SUCCESS', null);
  }

  resetPasswordError(error: string): void {
    this.sendMessage('RESET_PASSWORD_ERROR', { error });
  }

  logoutSuccess(): void {
    this.sendMessage('LOGOUT_SUCCESS', null);
  }

  logoutError(error: string): void {
    this.sendMessage('LOGOUT_ERROR', { error });
  }

  verifySuccess(): void {
    this.sendMessage('VERIFY_SUCCESS', null);
  }

  verifyError(error: string): void {
    this.sendMessage('VERIFY_ERROR', { error });
  }

  updatePasswordSuccess(): void {
    this.sendMessage('UPDATE_PASSWORD_SUCCESS', null);
  }

  updatePasswordError(error: string): void {
    this.sendMessage('UPDATE_PASSWORD_ERROR', { error });
  }
}

// ----------------------------------
// Typings
//

type PostMessageError = {
  error: string;
};

export interface PostMessageMap {
  INFO: any;
  INIT: null;
  ALREADY_AUTHED: null;
  AUTH_SUCCESS: null;
  AUTH_ERROR: PostMessageError;
  REGISTER_SUCCESS: null;
  REGISTER_ERROR: PostMessageError;
  RESET_PASSWORD_SUCCESS: null;
  RESET_PASSWORD_ERROR: PostMessageError;
  LOGOUT_SUCCESS: null;
  LOGOUT_ERROR: PostMessageError;
  VERIFY_SUCCESS: null;
  VERIFY_ERROR: PostMessageError;
  UPDATE_PASSWORD_SUCCESS: null;
  UPDATE_PASSWORD_ERROR: PostMessageError;
}

export interface Message<K extends keyof PostMessageMap> {
  id: typeof POST_MESSAGE_ID;
  type: K;
  payload: PostMessageMap[K];
}
