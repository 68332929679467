import { createContext, useContext } from 'react';
import { AccountsClient, ConnectClient } from 'src/services/api';
import { ApiService } from 'src/services/apiService';
import { API_SERVER } from 'src/settings';

export interface ApiContext {
  apiService: ApiService;
  accountsClient: AccountsClient;
  connectClient: ConnectClient;
}

const apiService = new ApiService(API_SERVER);
const accountsClient = new AccountsClient(apiService);
const connectClient = new ConnectClient(apiService);

export const DEFAULT_API_VALUES: ApiContext = {
  apiService,
  accountsClient,
  connectClient,
};

const ctx = createContext<ApiContext>(DEFAULT_API_VALUES);

export const ApiProvider = ctx.Provider;

export function useAPI(): Omit<ApiContext, 'apiService'> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { apiService, ...res } = useContext(ctx);
  return res;
}
