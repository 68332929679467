import { LogoWrapper, Link } from './Logos.styled';
import { DeviceType } from 'src/context/device/device.typings';
import { useDevice } from '@hooks';

type LogoItem = {
  alt: string;
  href?: string;
  urls: Record<DeviceType, string>;
};

const LOGO_ITEMS: LogoItem[] = [
  {
    alt: 'MRN',
    href: 'https://miniroyale.io',
    urls: {
      mobile: '/assets/mrn-320.png',
      tablet: '/assets/mrn-260.png',
      desktop: '/assets/mrn-260.png',
    },
  },
];

export function Logos() {
  const { device, isMobile } = useDevice();

  return (
    <>
      {LOGO_ITEMS.map((item, index) => (
        <LogoWrapper key={`logo-${index}`}>
          {item.href ? (
            <Link href={item.href} target="_blank" rel="noreferrer">
              <img alt={item.alt} src={item.urls[device]} width={isMobile ? '100%' : undefined} />
            </Link>
          ) : (
            <img alt={item.alt} src={item.urls[device]} width={isMobile ? '100%' : undefined} />
          )}
        </LogoWrapper>
      ))}
    </>
  );
}
