import { Button, Typography } from '@components/ui';
import { useAPI, useFormExtended, useMessaging } from '@hooks';
import { TextLink } from '@components/TextLink';
import { Form } from '@components/Form';
import { PasswordField } from '@components/PasswordField';
import { ErrorText } from '@components/ErrorText';
import { Controller } from 'react-hook-form';
import { useState } from 'react';
import { RoutePaths } from 'src/routes';
import { ERRORS, PASSWORD_RE } from 'src/constants';
import { useSearchParams } from 'react-router-dom';

interface FormValues {
  password: string;
  password2: string;
}

interface UpdatePasswordFormProps {
  onSuccess: (values: FormValues) => void;
}

export function UpdatePasswordForm(props: UpdatePasswordFormProps) {
  const { onSuccess } = props;

  const [searchParams] = useSearchParams();
  const messaging = useMessaging();
  const { accountsClient } = useAPI();
  const [fetching, setFetching] = useState<boolean>(false);
  const { handleSubmit, control, watch, setError, clearCustomError, error } = useFormExtended<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      password: '',
      password2: '',
    },
  });

  const submit = (values: FormValues) => {
    setFetching(true);
    const token = searchParams.get('token') || '';

    accountsClient
      .updatePassword(token, values.password)
      .then(() => {
        setFetching(false);
        onSuccess(values);
        messaging.updatePasswordSuccess();
      })
      .catch((e) => {
        setFetching(false);
        setError(e.field || null, e);
        messaging.updatePasswordError(e);
      });
  };

  return (
    <Form fetching={fetching} onChange={clearCustomError} onSubmit={handleSubmit(submit)}>
      <Typography sx={{ padding: '0 60px' }} variant="subtitle1" component="h2">
        Choose a new password
      </Typography>

      {error?.message && <ErrorText>{error.message}</ErrorText>}

      {/* Password */}
      <Controller
        name="password"
        control={control}
        rules={{
          required: ERRORS.PASSWORD_EMPTY,
          minLength: { value: 6, message: ERRORS.PASSWORD_RULE_LENGTH },
          maxLength: { value: 30, message: ERRORS.PASSWORD_RULE_LENGTH },
          pattern: {
            value: PASSWORD_RE,
            message: ERRORS.PASSWORD_RULE_CONTAINS,
          },
        }}
        render={({ field, fieldState }) => (
          <PasswordField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="New password"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      {/* Repeat password */}
      <Controller
        name="password2"
        control={control}
        rules={{
          required: ERRORS.PASSWORD_EMPTY,
          validate: (val: string) => {
            if (watch('password') !== val) {
              return ERRORS.PASSWORD_MATCH;
            }
          },
        }}
        render={({ field, fieldState }) => (
          <PasswordField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="Repeat your new password"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      <Button size="large" color="secondary" type="submit" variant="contained" fullWidth>
        Change password
      </Button>

      <TextLink align="center" to={RoutePaths.SIGN_IN} size="large">
        Go back to Sign In
      </TextLink>
    </Form>
  );
}
