import { memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { TextLink as TextLinkStyled } from './TextLink.styled';

type TextLinkProps = Omit<LinkProps, 'to'> & {
  href?: string;
  size?: 'large' | 'medium';
  align?: 'left' | 'right' | 'center';
  to?: string;
};

export const TextLink = memo(function TextLink$(props: TextLinkProps) {
  const { href, ...rest } = props;

  if (href) {
    return <TextLinkStyled href={href} {...rest} />;
  }

  return <TextLinkStyled as={Link} {...rest} />;
});
