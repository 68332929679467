import { Box } from '@components/Box';
import { PasswordField } from '@components/PasswordField';
import { TextLink } from '@components/TextLink';
import { Form } from '@components/Form/Form';
import { Button, Checkbox, TextField, Typography, FormControlLabel } from '@components/ui';
import { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { useAPI, useFormExtended, useMessaging } from 'src/hooks';
import { Row } from './SignIn.styled';
import { PRIVACY_POLICY_URL } from 'src/settings';
import { RoutePaths } from 'src/routes';
import { OutletContext } from 'src/context';
import { Controller } from 'react-hook-form';
import { DEFAULT_EMAIL_VALIDATION_RULES, DEFAULT_PASSWORD_VALIDATION_RULES } from 'src/validation';
import { ErrorText } from '@components/ErrorText';

interface FormValues {
  email: string;
  password: string;
  remember: boolean;
}

export function SignIn() {
  const authState = useOutletContext<OutletContext>();
  const messaging = useMessaging();
  const { connectClient } = useAPI();
  const [fetching, setFetching] = useState<boolean>(false);
  const { control, handleSubmit, setError, error, clearCustomError } = useFormExtended<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
  });

  const submit = (values: FormValues) => {
    setFetching(true);

    connectClient
      .auth(values.email, values.password, values.remember)
      .then(() => {
        setFetching(false);
        messaging.authSuccess();
        authState.setIsAuth?.(true);
      })
      .catch((e) => {
        setFetching(false);
        setError(e.field || null, e);
        messaging.authError(e);
      });
  };

  return (
    <Form onChange={clearCustomError} onSubmit={handleSubmit(submit)} fetching={fetching}>
      <Typography sx={{ padding: '0 60px' }} variant="subtitle1" component="h2">
        Login with your Faraway account
      </Typography>

      {error?.message && <ErrorText>{error.message}</ErrorText>}

      <Controller
        name="email"
        rules={DEFAULT_EMAIL_VALIDATION_RULES}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="Email address"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={DEFAULT_PASSWORD_VALIDATION_RULES}
        render={({ field, fieldState }) => (
          <PasswordField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="Password"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      <Row>
        <Controller
          name="remember"
          control={control}
          render={({ field }) => <FormControlLabel control={<Checkbox {...field} />} label="Remember me" />}
        />

        <TextLink to="/reset">Forgot Your Password?</TextLink>
      </Row>

      <Button size="large" color="secondary" type="submit" variant="contained" fullWidth>
        Log in
      </Button>
      <TextLink href={PRIVACY_POLICY_URL} target="_blank" align="center" size="large">
        Privacy Policy
      </TextLink>

      <Box>
        <Typography sx={{ opacity: 0.7, marginBottom: '7px' }}>Don’t have a Faraway account?</Typography>
        <Button component={Link} to={RoutePaths.SIGN_UP} variant="text" color="secondary">
          Sign Up
        </Button>
      </Box>
    </Form>
  );
}
