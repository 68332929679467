import { useState } from 'react';
import { SuccessBlock } from '@components/SuccessBlock';
import { Button } from '@components/ui';
import { UpdatePasswordForm } from './Form';
import { RoutePaths } from 'src/routes';
import { Link } from 'react-router-dom';

export function UpdatePassword() {
  const [success, setSuccess] = useState<boolean>(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  if (success) {
    return (
      <>
        <SuccessBlock size="large" message="Your password has been updated successfuly!" />
        <Button component={Link} to={RoutePaths.SIGN_IN} size="large" color="secondary" variant="contained" fullWidth>
          Go to my account
        </Button>
      </>
    );
  }

  return <UpdatePasswordForm onSuccess={handleSuccess} />;
}
