import { useState } from 'react';
import { AccountCreated } from './AccountCreated';
import { SignUpForm } from './SignUpForm';
import { FormValues } from './typings';

export function SignUp() {
  const [created, setCreated] = useState<boolean>(false);

  const [values, setValues] = useState<FormValues>({
    email: '',
    password: '',
    password2: '',
    username: '',
  });

  const handleSubmit = (values: FormValues) => {
    setValues(values);
    setCreated(true);
  };

  if (created) {
    return <AccountCreated values={values} />;
  }

  return <SignUpForm onSubmit={handleSubmit} />;
}
