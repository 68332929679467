import { AlreadyHaveBlock } from '@components/AlreadyHaveBlock';
import { Typography } from '@components/ui';

export function NeedConfirm() {
  return (
    <>
      <Typography sx={{ padding: '0 60px' }} variant="subtitle1" component="h2">
        Check your email
      </Typography>

      <Typography variant="body2">
        Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam
        folder.
      </Typography>

      <AlreadyHaveBlock />
    </>
  );
}
