import { FormHTMLAttributes } from 'react';
import { Container, PreloadContainer, Form as StyledForm } from './Form.styled';
import { CircularProgress } from '@components/ui';

type FormProps = FormHTMLAttributes<HTMLFormElement> & {
  fetching?: boolean;
};

export function Form(props: FormProps) {
  const { fetching, ...formProps } = props;

  return (
    <Container>
      <StyledForm {...formProps} />

      {fetching && (
        <PreloadContainer>
          <CircularProgress />
        </PreloadContainer>
      )}
    </Container>
  );
}
