import { MessagingTransport } from './transport';

export class MessagingService implements MessagingTransport {
  constructor(private transports: MessagingTransport[] = []) {}

  addTransport = (transport: MessagingTransport) => {
    this.transports.push(transport);
  };

  init = () => {
    this.transports.forEach((t) => t.init?.());
  };

  authSuccess = () => {
    this.transports.forEach((t) => t.authSuccess());
  };

  registerSuccess = () => {
    this.transports.forEach((t) => t.registerSuccess?.());
  };

  registerError = (err: string) => {
    this.transports.forEach((t) => t.registerError?.(err));
  };

  authError = (err: string) => {
    this.transports.forEach((t) => t.authError?.(err));
  };

  resetPasswordError = (err: string) => {
    this.transports.forEach((t) => t.resetPasswordError?.(err));
  };

  resetPasswordSuccess = () => {
    this.transports.forEach((t) => t.resetPasswordSuccess?.());
  };

  logoutSuccess = () => {
    this.transports.forEach((t) => t.logoutSuccess?.());
  };

  logoutError = (err: string) => {
    this.transports.forEach((t) => t.logoutError?.(err));
  };

  verifySuccess = () => {
    this.transports.forEach((t) => t.verifySuccess?.());
  };

  verifyError = (err: string) => {
    this.transports.forEach((t) => t.verifyError?.(err));
  };

  updatePasswordSuccess = () => {
    this.transports.forEach((t) => t.updatePasswordSuccess?.());
  };

  updatePasswordError = (err: string) => {
    this.transports.forEach((t) => t.updatePasswordError?.(err));
  };

  alreadyAuthed = () => {
    this.transports.forEach((t) => t.alreadyAuthed?.());
  };
}
