import { ErrorContent, ErrorWrap, Icon } from './ErrorText.styled';

interface ErrorTextProps {
  children?: React.ReactNode;
}

export function ErrorText(props: ErrorTextProps) {
  const { children } = props;
  return (
    <ErrorWrap>
      <Icon />
      <ErrorContent>{children}</ErrorContent>
    </ErrorWrap>
  );
}
