import styled from '@emotion/styled';
import { css } from '@emotion/react';

type Align = 'left' | 'right' | 'center';

interface TextLinkProps {
  size?: 'large' | 'medium';
  align?: Align;
}

const alignMap: Record<Align, string> = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

export const TextLink = styled.a<TextLinkProps>`
  display: inline-flex;
  font-weight: 700;
  font-size: 14px;
  color: #161616;
  text-decoration: none;

  ${(p) =>
    p.align &&
    css`
      align-self: ${alignMap[p.align]};
    `}

  ${(p) =>
    p.size === 'large' &&
    css`
      text-transform: uppercase;
    `}
`;
