import styled from '@emotion/styled';

export const LogoWrapper = styled.div`
  display: flex;
  margin-bottom: 7px;
`;

export const Link = styled.a`
  display: flex;
  width: 100%;
`;
