import { createTheme } from '@mui/material';
import { Checkbox } from '@components/Checkbox';

export const muiTheme = createTheme({
  palette: {
    text: {
      primary: '#222731',
    },
    primary: {
      main: '#2B80EE',
    },
    secondary: {
      main: '#EA3A59',
    },
    info: {
      main: '#596579',
    },
  },
  typography: {
    allVariants: {
      fontFamily: ['HelveticaNeueCyr', '-apple-system', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(','),
      fontSize: 16,
      lineHeight: '16px',
    },
    fontWeightRegular: 550,
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'subtitle1' },
          style: {
            textAlign: 'center',
            margin: '29px 0',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '30px',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            color: '#596579',
            textAlign: 'center',
            fontSize: '16px',
            lineHeight: '25px',
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          marginBottom: '15px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: '26px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: '#161616',
            fontSize: '14px',
          },
        },
        {
          props: { size: 'large', variant: 'contained' },
          style: {
            'fontWeight': 700,
            'fontSize': '20px',
            'lineHeight': '24px',
            'padding': '23px',
            'marginBottom': '25px',
            'borderRadius': '10px',
            'boxShadow': '6px 6px 0px #e9e9e9',
            'background': 'linear-gradient(to right, #4d32e5 50%, #ea3a59 50%)',
            'backgroundSize': '200% 100%',
            'backgroundPosition': 'right bottom',
            'transition': 'background-position 0.2s ease',
            '&:hover': {
              boxShadow: '6px 6px 0px #e9e9e9',
              backgroundPosition: 'left bottom',
            },
          },
        },
        {
          props: { size: 'large', variant: 'contained', disabled: true },
          style: {
            color: 'rgba(0, 0, 0, 0.26)',
            boxShadow: 'none',
            background: 'rgba(0, 0, 0, 0.12)',
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          color: '#596579',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <Checkbox />,
        checkedIcon: <Checkbox checked />,
      },
    },
  },
});
