import { ApiService } from '../apiService';
import { ApiClient, ErrorResponse } from './apiClient';
import { ConnectPromiseClient, Authenticate, Logout, Rescue } from '@modules/api';
import { ERROR_FIELD, SERVER_ERRORS_MAP } from './errors';
import { ERRORS } from 'src/constants';

export class ConnectClient extends ApiClient<ConnectPromiseClient> {
  constructor(api: ApiService) {
    super(api, ConnectPromiseClient);
  }

  auth = (email: string, password: string, remember: boolean) => {
    const req = new Authenticate.Request();
    req.setEmail(email);
    req.setPassword(password);
    req.setRemember(remember ? Authenticate.Request.Remember.ALWAYS : Authenticate.Request.Remember.NEVER);

    return this.grpc
      .authenticate(req)
      .then((res) => this.normalizeResponse<Authenticate.Response.AsObject>(res))
      .catch(this.errorHandler);
  };

  logout = () => {
    const req = new Logout.Request();
    return this.grpc
      .logout(req)
      .then((res) => this.normalizeResponse<Logout.Response.AsObject>(res))
      .catch(this.errorHandler);
  };

  rescue = (email: string) => {
    const req = new Rescue.Request();
    req.setEmail(email);
    return this.grpc
      .rescue(req)
      .then((res) => this.normalizeResponse<Rescue.Response.AsObject>(res))
      .catch(this.rescueErrorHandler);
  };

  private rescueErrorHandler = (e: Error) => {
    const res: ErrorResponse = {
      message: RESCUE_ERRORS_MAP[e.message] || e.message,
      field: (ERROR_FIELD as any)[e.message] || null,
      base: e,
    };
    return Promise.reject(res);
  };
}

// ----------------------------------
// Errors
//
const RESCUE_ERRORS_MAP: Record<string, string> = {
  ...SERVER_ERRORS_MAP,
  'nothing found': ERRORS.EMAIL_NOT_FOUND,
};
