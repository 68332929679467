import { CheckboxBorder } from './Checkbox.styled';

export function Checkbox({ checked }: { checked?: boolean }) {
  return (
    <CheckboxBorder>
      {checked && (
        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33325 5.58337L5.70825 9.95837L13.8333 1.83337" stroke="#161616" strokeWidth="3" />
        </svg>
      )}
    </CheckboxBorder>
  );
}
