import { UseControllerProps } from 'react-hook-form';
import { EMAIL_RE, ERRORS, PASSWORD_RE, USERNAME_RE } from './constants';

type Rules = UseControllerProps['rules'];

export const DEFAULT_EMAIL_VALIDATION_RULES: Rules = {
  required: ERRORS.EMAIL_EMPTY,
  pattern: {
    value: EMAIL_RE,
    message: ERRORS.EMAIL_INCORRECT,
  },
};

export const DEFAULT_PASSWORD_VALIDATION_RULES: Rules = {
  required: ERRORS.PASSWORD_EMPTY,
  minLength: { value: 6, message: ERRORS.PASSWORD_RULE_LENGTH },
  maxLength: { value: 30, message: ERRORS.PASSWORD_RULE_LENGTH },
  pattern: {
    value: PASSWORD_RE,
    message: ERRORS.PASSWORD_RULE_CONTAINS,
  },
};

export const DEFAULT_USERNAME_VALIDATION_RULES: Rules = {
  required: ERRORS.USERNAME_EMPTY,
  maxLength: { value: 20, message: ERRORS.USERNAME_RULE },
  pattern: {
    value: USERNAME_RE,
    message: ERRORS.USERNAME_RULE_CONTAINS,
  },
};
