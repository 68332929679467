import { Box } from '@components/Box';
import { Button, TextField, Typography } from '@components/ui';
import { Link } from 'react-router-dom';
import { TextLink } from '@components/TextLink';
import { useAPI, useFormExtended, useMessaging } from '@hooks';
import { Controller } from 'react-hook-form';
import { useState } from 'react';
import { Form } from '@components/Form';
import { RoutePaths } from 'src/routes';
import { DEFAULT_EMAIL_VALIDATION_RULES } from 'src/validation';
import { ErrorText } from '@components/ErrorText';

interface FormValues {
  email: string;
}

interface ResetFormProps {
  onSuccess: (values: FormValues) => void;
}

export function ResetForm(props: ResetFormProps) {
  const { onSuccess } = props;

  const messaging = useMessaging();
  const { connectClient } = useAPI();
  const [fetching, setFetching] = useState<boolean>(false);
  const { handleSubmit, control, setError, clearCustomError, error } = useFormExtended<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const submit = (values: FormValues) => {
    setFetching(true);

    connectClient
      .rescue(values.email)
      .then(() => {
        setFetching(false);
        onSuccess(values);
        messaging.resetPasswordSuccess();
      })
      .catch((e) => {
        setFetching(false);
        setError(e.field || null, e);
        messaging.resetPasswordError(e);
      });
  };

  return (
    <Form fetching={fetching} onChange={clearCustomError} onSubmit={handleSubmit(submit)}>
      <Typography sx={{ padding: '0 60px' }} variant="subtitle1" component="h2">
        Reset your password
      </Typography>

      {error?.message && <ErrorText>{error.message}</ErrorText>}

      <Controller
        name="email"
        rules={DEFAULT_EMAIL_VALIDATION_RULES}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="Email address"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      <Button size="large" color="secondary" type="submit" variant="contained" fullWidth>
        Reset password
      </Button>

      <TextLink align="center" to={RoutePaths.SIGN_IN} size="large">
        Cancel
      </TextLink>

      <Box>
        <Typography sx={{ opacity: 0.7, marginBottom: '7px' }}>Don’t have a Faraway account?</Typography>
        <Button component={Link} to={RoutePaths.SIGN_UP} variant="text" color="secondary">
          Sign Up
        </Button>
      </Box>
    </Form>
  );
}
