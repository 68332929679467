import styled from '@emotion/styled';

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  flex-direction: column;
  background-color: #f5f7f9;
  border-radius: 10px;
  margin: 24px 0 0;
`;
