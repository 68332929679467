import { Button, Typography } from '@components/ui';
import { Container, SuccessBlock } from './AccountCreated.styled';
import { DoneAll } from '@components/icons';
import { AlreadyHaveBlock } from '@components/AlreadyHaveBlock';
import { useState } from 'react';
import { useAPI, useMessaging } from 'src/hooks';
import { FormValues } from '../typings';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from 'src/context';

interface AccountCreatedProps {
  values: FormValues;
}

export function AccountCreated(props: AccountCreatedProps) {
  const { values } = props;
  const authState = useOutletContext<OutletContext>();
  const { connectClient } = useAPI();
  const messaging = useMessaging();
  const [, setFetching] = useState<boolean>(false);
  const [, setError] = useState<string>('');

  const handleSignIn = () => {
    setFetching(true);
    connectClient
      .auth(values.email, values.password, false)
      .then(() => {
        setFetching(false);
        messaging.authSuccess();
        authState.setIsAuth(true);
      })
      .catch((e) => {
        setFetching(false);
        setError(e.message);
        messaging.authError(e.message);
      });
  };

  return (
    <>
      <Container>
        <SuccessBlock>
          <DoneAll sx={{ fontSize: '68px' }} />
          <Typography sx={{ margin: '12px 0', textAlign: 'center' }}>
            Congratulations! You’ve successfully created your account
          </Typography>
        </SuccessBlock>

        <Button onClick={handleSignIn} size="large" color="secondary" variant="contained" fullWidth>
          Go to my account
        </Button>

        <Typography sx={{ marginBottom: '60px' }} variant="subtitle1" component="h2">
          Please check your email for a verification link
        </Typography>
      </Container>

      <AlreadyHaveBlock />
    </>
  );
}
