import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DeviceParams } from 'src/context/device/device.typings';

type LayoutProps = Partial<DeviceParams>;

export const Layout = styled.div<LayoutProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 16px 0;
  background-color: #f5f7f9;

  ${(p) =>
    p.isMobile &&
    css`
      background-color: #fff;
    `}
`;

export const LayoutContainer = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  max-width: 540px;

  ${(p) =>
    p.isMobile &&
    css`
      margin: 0;
      padding: 20px;
    `}
`;

export const LayoutBody = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 48px 65px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 26px;

  ${(p) =>
    p.isMobile &&
    css`
      padding: 0;
      border-radius: 0;
      margin-bottom: 50px;
      background: none;
      box-shadow: none;
    `}
`;

export const LayoutFooter = styled.div<LayoutProps>`
  display: flex;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  justify-content: center;
`;
