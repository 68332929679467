import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Pages from './pages';
import { RoutePaths } from './routes';

const Router = process.env.HASH_ROUTER === 'true' ? HashRouter : BrowserRouter;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={RoutePaths.SIGN_UP} element={<Pages.SignUp />} />
        <Route path={RoutePaths.SIGN_IN} element={<Pages.SignIn />} />
        <Route path={RoutePaths.RESET_PASSWORD} element={<Pages.Reset />} />
        <Route path={RoutePaths.VERIFY} element={<Pages.Verify />} />
        <Route path={RoutePaths.UPDATE_PASSWORD} element={<Pages.UpdatePassword />} />
      </Route>
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
