import { PasswordField } from '@components/PasswordField';
import { Form } from '@components/Form';
import { Button, Checkbox, TextField, Typography, FormControlLabel } from '@components/ui';
import { AlreadyHaveBlock } from '@components/AlreadyHaveBlock';
import { FormValues } from '../typings';
import { TERMS_URL } from 'src/settings';
import { Controller } from 'react-hook-form';
import { ERRORS } from 'src/constants';
import { LinkInCheckbox } from './SignUpForm.styled';
import { useState } from 'react';
import { useAPI, useFormExtended, useMessaging } from 'src/hooks';
import {
  DEFAULT_EMAIL_VALIDATION_RULES,
  DEFAULT_PASSWORD_VALIDATION_RULES,
  DEFAULT_USERNAME_VALIDATION_RULES,
} from 'src/validation';
import { ErrorText } from '@components/ErrorText';

interface SignUpFormProps {
  onSubmit: (values: FormValues) => void;
}

export function SignUpForm(props: SignUpFormProps) {
  const { onSubmit } = props;

  const { accountsClient } = useAPI();
  const messaging = useMessaging();
  const [fetching, setFetching] = useState<boolean>(false);
  const [flags, setFlags] = useState<{ adult: boolean; agree: boolean }>({
    adult: false,
    agree: false,
  });

  const { handleSubmit, control, watch, setError, clearCustomError, error } = useFormExtended({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      password2: '',
      username: '',
    },
  });

  const submit = (values: FormValues) => {
    setFetching(true);
    accountsClient
      .createAccount(values.email, values.username, values.password)
      .then(() => {
        setFetching(false);
        messaging.registerSuccess();
        onSubmit(values);
      })
      .catch((e) => {
        setFetching(false);
        setError(e.field || null, e);
        messaging.registerError(e);
      });
  };

  const onToggleFlag = (flag: 'adult' | 'agree') => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFlags((state) => ({ ...state, [flag]: checked }));
  };

  const disabled = !flags.adult || !flags.agree;

  return (
    <Form fetching={fetching} onChange={clearCustomError} onSubmit={handleSubmit(submit)}>
      <Typography sx={{ padding: '0 60px' }} variant="subtitle1" component="h2">
        Sign Up
      </Typography>

      {error?.message && <ErrorText>{error.message}</ErrorText>}

      {/* E-Mail */}
      <Controller
        name="email"
        rules={DEFAULT_EMAIL_VALIDATION_RULES}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="Email address"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      {/* Username */}
      <Controller
        name="username"
        control={control}
        rules={DEFAULT_USERNAME_VALIDATION_RULES}
        render={({ field, fieldState }) => (
          <TextField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            error={!!fieldState.error}
            label="Username"
            variant="outlined"
          />
        )}
      />

      {/* Password */}
      <Controller
        name="password"
        control={control}
        rules={DEFAULT_PASSWORD_VALIDATION_RULES}
        render={({ field, fieldState }) => (
          <PasswordField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="Password"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      {/* Repeat password */}
      <Controller
        name="password2"
        control={control}
        rules={{
          required: ERRORS.PASSWORD_EMPTY,
          validate: (val: string) => {
            if (watch('password') !== val) {
              return ERRORS.PASSWORD_MATCH;
            }
          },
        }}
        render={({ field, fieldState }) => (
          <PasswordField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            label="Repeat password"
            variant="outlined"
            error={!!fieldState.error}
          />
        )}
      />

      <FormControlLabel
        control={<Checkbox value={flags.agree} onChange={onToggleFlag('agree')} />}
        label={
          <>
            <span>I have read and agree to the </span>
            <LinkInCheckbox href={TERMS_URL} rel="noreferrer" target="_blank">
              terms of service
            </LinkInCheckbox>
          </>
        }
      />
      <FormControlLabel
        control={<Checkbox value={flags.adult} onChange={onToggleFlag('adult')} />}
        label="I certify that I am at least 18 years of age"
      />

      <Button size="large" color="secondary" type="submit" variant="contained" disabled={disabled} fullWidth>
        Sign up
      </Button>

      <AlreadyHaveBlock />
    </Form>
  );
}
