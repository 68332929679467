export interface MessagingTransport {
  init?(): void;
  alreadyAuthed?(): void;
  authError?(err: string): void;
  registerSuccess?(): void;
  registerError?(err: string): void;
  resetPasswordSuccess?(): void;
  resetPasswordError?(err: string): void;
  logoutSuccess?(): void;
  logoutError?(err: string): void;
  verifySuccess?(): void;
  verifyError?(err: string): void;
  updatePasswordSuccess?(): void;
  updatePasswordError?(err: string): void;
}

export abstract class MessagingTransport {
  abstract authSuccess(): void;
}
