import { Divider } from './PasswordField.styled';
import { TextFieldProps } from '@mui/material';
import { useToggle } from '@hooks';
import { TextField, InputAdornment, IconButton } from '@components/ui';
import { Visibility, VisibilityOff } from '@components/icons';

export function PasswordField(props: TextFieldProps) {
  const [type, toggleType] = useToggle(['text', 'password'], 'password');
  return (
    <TextField
      InputProps={{
        endAdornment: <Adornment onClick={toggleType} closed={type === 'password'} />,
      }}
      type={type}
      {...props}
    />
  );
}

// ----------------------------------
// Adornment
//
interface AdornmentProps {
  onClick: () => void;
  closed?: boolean;
}

function Adornment(props: AdornmentProps) {
  const { onClick, closed } = props;
  return (
    <InputAdornment position="end">
      <Divider />
      <IconButton sx={{ margin: '0 -4px 0 8px' }} aria-label="toggle password visibility" onClick={onClick} edge="end">
        {closed ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
}
