import styled from '@emotion/styled';
import Check from '@mui/icons-material/Check';
import { styled as muiStyled } from '@mui/system';

export const CheckboxBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #d6dcea;
  border-radius: 5px;
`;

export const CheckIcon = muiStyled(Check)({
  color: '#161616',
});
